import HayaSelect from "haya-select/src/select"

const optionsCallback = async ({searchValue, values}) => {
  const query = User
    .ransack({s: "first_name asc"})
    .select({User: ["id", "name"]})
    .limit(20)

  if (searchValue) query.ransack({first_name_or_last_name_cont_any_word: searchValue})
  if (values) query.ransack({id_eq_any: values})

  const users = await query.toArray()

  return users.map((user) => ({
    text: user.name(),
    value: user.id()
  }))
}

export default function componentsUsersSelect(props) {
  const {className, ...restProps} = props

  return (
    <HayaSelect
      className={classNames("components-users-select", className)}
      options={optionsCallback}
      search
      {...restProps}
    />
  )
}
