export default class ComponentsProjectsNav extends BaseComponent {
  static propTypes = {
    project: PropTypes.instanceOf(Project).isRequired
  }

  render() {
    const {className, project, ...restProps} = this.props

    return (
      <ul className={classNames("nav", "nav-pills", className)} {...restProps}>
        <li className="nav-item">
          <Link className={this.navLinkClassNames("general")} to={Routes.projectPath(project.id())}>
            {I18n.t("js.shared.general")}
          </Link>
        </li>
        <li className="nav-item">
          <Link className={this.navLinkClassNames("taskTypes")} to={Routes.projectTaskTypesPath(project.id())}>
            {Project.humanAttributeName("taskTypes")}
          </Link>
        </li>
      </ul>
    )
  }

  navLinkClassNames(identifier) {
    const classNames = ["nav-link"]

    if (this.props.active == identifier) {
      classNames.push("active")
    }

    return classNames.join(" ")
  }
}
