export default class ComponentsTasksCloseButton extends BaseComponent {
  static propTypes = {
    className: PropTypes.string,
    task: PropTypes.instanceOf(Task).isRequired
  }

  render() {
    const {children, className, task, ...restProps} = this.props

    return (
      <Button
        className={classNames("components--tasks--close-button", className)}
        disabled={!task.can("stateEventClose")}
        icon="user"
        onClick={this.t.onClick}
        {...restProps}
      >
        {I18n.t("js.components.tasks.close_button.close")}
      </Button>
    )
  }

  onClick = async (e) => {
    e.preventDefault()

    if (!confirm(I18n.t("js.shared.are_you_sure"))) return

    try {
      await User.stateEvent({model: this.p.task, state_event: "close"})
      FlashMessage.success(I18n.t("js.components.tasks.close_button.the_task_was_closed"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}
