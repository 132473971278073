export default class ComponentsUserRolesTable extends BaseComponent {
  static propTypes = {
    className: PropTypes.string,
    user: PropTypes.instanceOf(User)
  }

  render() {
    const {className, user, ...restProps} = this.props

    return (
      <ApiMakerTable
        appHistory={AppHistory}
        className={classNames("components-user-roles-table", className)}
        columns={this.columns}
        controls={this.controlsContent}
        defaultParams={{s: "role asc"}}
        editModelPath={this.editModelPath}
        header={UserRole.modelName().human({count: 2})}
        modelClass={UserRole}
        select={{UserRole: ["translatedRole"]}}
        {...restProps}
      />
    )
  }

  controlsContent = () => {
    const {user} = this.props

    return (
      <div>
        {user &&
          <Link className="btn btn-secondary btn-sm new-user-role-button" to={Routes.newUserRolePath({user_role: {user_id: user.id()}})}>
            {I18n.t("js.components.user_roles.table.new_user_role")}
          </Link>
        }
      </div>
    )
  }

  editModelPath = ({userRole}) => Routes.editUserRolePath(userRole.id())
  roleContent = ({userRole}) => userRole.translatedRole()

  columns = [
    {
      attribute: "id",
      sortKey: "id"
    },
    {
      attribute: "role",
      content: this.roleContent,
      sortKey: "role"
    }
  ]
}
