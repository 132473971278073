export default class ComponentsUsersDestroyButton extends BaseComponent {
  static propTypes = {
    onDestroyed: PropTypes.func,
    user: PropTypes.instanceOf(User).isRequired
  }

  render() {
    const {className, user, ...restProps} = this.props

    return (
      <a
        className={classNames("components-users-destroy-button", className)}
        href="#"
        onClick={this.t.onUserDestroyClicked}
        title={I18n.t("js.components.users.destroy_button.delete_user")}
        {...restProps}
      >
        <i className="fa fa-remove" />
      </a>
    )
  }

  onUserDestroyClicked = async (e) => {
    e.preventDefault()

    if (!confirm(I18n.t("js.shared.are_you_sure"))) return

    const {user} = this.p
    const {onDestroyed} = this.props

    try {
      await user.destroy()

      if (onDestroyed) onDestroyed()
    } catch (error) {
      FlashNotifications.errorResponse(error)
    }
  }
}
