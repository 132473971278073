export default function ComponentsTasksProgress(props) {
  const {className, task, ...restProps} = props

  return (
    <div className={classNames("progress", className)} style={{marginBottom: "0px"}} {...restProps}>
      <div className="progress-bar" style={{width: `${parseInt(task.progress() * 100)}%`}}>
        <span>
          {task.childrenDoneCount()} / {task.childrenCount()}
        </span>
      </div>
    </div>
  )
}
