export default class ComponentsOrganizationsDestroyButton extends BaseComponent {
  render() {
    const {className, organization, ...restProps} = this.props

    return (
      <a className={classNames("components--organizations--destroy-button", className)} href="#" onClick={this.t.onDestroyOrganizationClicked} {...restProps}>
        <i className="fa fa-remove" />
      </a>
    )
  }

  onDestroyOrganizationClicked = async (e) => {
    e.preventDefault()

    if (!confirm(I18n.t("js.shared.are_you_sure"))) return

    try {
      await this.p.organization.destroy()
      FlashMessage.success(I18n.t("js.components.organizations.destroy_button.the_organization_was_deleted"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}
