import "./style"

export default class ComponentsLayoutNavItemDropdown extends BaseComponent {
  rootRef = React.createRef()

  shape = new Shape(this, {
    active: false
  })

  render() {
    const {children, label} = this.p

    return (
      <div className="components--layout--nav-item-dropdown" ref={this.t.rootRef}>
        <a className="nav-item-dropdown-link" data-active={this.s.active} href="#" onClick={this.t.onClick}>
          <div style={{display: "flex", alignItems: "center"}}>
            <div>{label}</div>
            <div><i className="fa fa-chevron-down" style={{fontSize: "11px", marginLeft: "3px"}} /></div>
          </div>
        </a>
        {this.s.active &&
          <div style={{position: "relative"}}>
            <div className="nav-item-dropdown-menu">
              <EventListener event="mouseup" onCalled={this.t.onWindowMouseUp} target={window} />
              {children}
            </div>
          </div>
        }
      </div>
    )
  }

  onClick = (e) => {
    e.preventDefault()
    this.shape.set({active: !this.s.active})
  }

  onWindowMouseUp = (e) => {
    const {rootRef} = this.t

    // Close the header actions menu if clicked happened outside
    if (this.s.active && rootRef.current && !rootRef.current.contains(e.target)) this.s.set({active: false})
  }
}
