export default class ComponentsInvoiceGroupsTable extends BaseComponent {
  render() {
    const {className, ...restProps} = this.props

    return (
      <ApiMakerTable
        appHistory={AppHistory}
        columns={this.columns}
        controls={this.controls}
        editModelPath={this.editModelPath}
        header={InvoiceGroup.modelName().human({count: 2})}
        modelClass={InvoiceGroup}
        {...restProps}
      />
    )
  }

  controls = () =>
    <Link className="new-invoice-group-button" to={Routes.newInvoiceGroupPath()}>
      <i className="fa fa-plus" />
    </Link>

  editModelPath = ({invoiceGroup}) => Routes.editInvoiceGroupPath(invoiceGroup.id())
  invoiceGroupContent = ({invoiceGroup}) => <InvoiceGroupLink invoiceGroup={invoiceGroup} />

  columns = [
    {
      attribute: "name",
      content: this.invoiceGroupContent,
      sortKey: "name"
    }
  ]
}
