export default class ComponentsUsersNav extends BaseComponent {
  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired
  }

  render() {
    const {className, user, ...restProps} = this.props

    return (
      <ul className={classNames("nav", "nav-pills", className)} {...restProps}>
        <li className="nav-item">
          <Link className={this.navLinkClassNames("general")} to={Routes.userPath(user.id())}>
            {I18n.t("js.shared.general")}
          </Link>
        </li>
        <li className="nav-item">
          <Link className={this.navLinkClassNames("userRoles")} to={Routes.userUserRolesPath(user.id())}>
            {User.humanAttributeName("userRoles")}
          </Link>
        </li>
      </ul>
    )
  }

  navLinkClassNames(identifier) {
    const classNames = ["nav-link"]

    if (this.props.active == identifier) {
      classNames.push("active")
    }

    return classNames.join(" ")
  }
}
