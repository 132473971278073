import HayaSelect from "haya-select/src/select"

const optionsCallback = async ({searchValue, values}) => {
  const query = Task
    .ransack({s: "name asc"})
    .select({Task: ["id", "name"]})
    .limit(20)

  if (searchValue) query.ransack({name_cont_any_word: searchValue})
  if (values) query.ransack({id_eq_any: values})

  const tasks = await query.toArray()

  return tasks.map((task) => ({
    text: task.name(),
    value: task.id()
  }))
}

export default function componentsTasksSelect(props) {
  const {className, ...restProps} = props

  return (
    <HayaSelect
      className={classNames("components--tasks--select", className)}
      options={optionsCallback}
      search
      {...restProps}
    />
  )
}
