const cache = {}

export default class ComponentsCountrySelect extends BaseComponent {
  shape = new Shape(this, {
    countriesLoaded: Boolean(cache.countriesCollection)
  })

  componentDidMount() {
    this.loadCountriesIfNotLoaded()
  }

  async loadCountriesIfNotLoaded() {
    if (!cache.countriesCollection) {
      const response = await Organization.countries()
      const countries = digg(response, "countries")

      cache.countriesCollection = countries.map((country) => [
        digg(country, "country_name"),
        digg(country, "country_code")
      ])

      this.shape.set({countriesLoaded: true})
    }
  }

  render() {
    const {className, ...restProps} = this.props

    if (cache.countriesCollection) {
      return (
        <Select
          className={classNames("components-countries-select", className)}
          options={cache.countriesCollection}
          {...restProps}
        />
      )
    }
  }
}
