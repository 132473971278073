export default class ComponentsTasksNav extends BaseComponent {
  static propTypes = {
    task: PropTypes.instanceOf(Task).isRequired
  }

  render() {
    const {className, task, ...restProps} = this.props

    return (
      <ul className={classNames("nav", "nav-pills", className)} {...restProps}>
        <li className="nav-item">
          <Link className={this.navLinkClassNames("general")} to={Routes.taskPath(task.id())}>
            {I18n.t("js.shared.general")}
          </Link>
        </li>
        <li className="nav-item">
          <Link className={this.navLinkClassNames("assigned-users")} to={Routes.taskAssignedUsersPath(task.id())}>
            {User.modelName().human({count: 2})} ({task.taskAssignedUsersCount()})
          </Link>
        </li>
        <li className="nav-item">
          <Link className={this.navLinkClassNames("comments")} to={Routes.taskCommentsPath(task.id())}>
            {Task.humanAttributeName("comments")} ({task.commentsCount()})
          </Link>
        </li>
        <li className="nav-item">
          <Link className={this.navLinkClassNames("timelogs")} to={Routes.taskTimelogsPath(task.id())}>
            {Task.humanAttributeName("timelogs")} ({task.timelogsCount()})
          </Link>
        </li>
      </ul>
    )
  }

  navLinkClassNames(identifier) {
    const classNames = ["nav-link"]

    if (this.props.active == identifier) {
      classNames.push("active")
    }

    return classNames.join(" ")
  }
}
