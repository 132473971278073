import "./style"
import DropdownItem from "./dropdown-item"
import Locales from "shared/locales"
import NavItem from "./nav-item"
import NavItemDropdown from "./nav-item-dropdown"
import React from "react"
import UsersSignIn from "components/users/sign-in"

class LocaleLink extends BaseComponent {
  render = () => <DropdownItem label={this.p.label} onClick={this.onClick} />
  onClick = () => this.p.onClick({locale: this.p.locale})
}

class ComponentsLayout extends BaseComponent {
  static defaultProps = {
    notFound: false,
    requireSignedIn: true
  }

  static propTypes = PropTypesExact({
    canCan: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.string,
    controls: PropTypes.node,
    currentUser: PropTypes.instanceOf(User),
    headerTitle: PropTypes.string,
    notFound: PropTypes.bool,
    requireSignedIn: PropTypes.bool.isRequired
  })

  layoutNavContainerRef = React.createRef()

  shape = new Shape(this, {
    showMenu: false
  })

  componentDidMount() {
    this.setWindowTitle()
  }

  componentDidUpdate() {
    this.setWindowTitle()
  }

  setWindowTitle() {
    const {headerTitle} = this.props

    if (headerTitle) {
      document.title = headerTitle
    } else {
      document.title = "AwesomeTasks"
    }
  }

  render() { // eslint-disable-line complexity
    const {canCan, children, className, controls, currentUser, headerTitle, notFound, requireSignedIn} = this.props

    return (
      <div className={classNames("components--layout", className)}>
        <div className="layout-nav">
          <div className="container">
            <div className="layout-nav-inside-container">
              <Link className="layout-brand-link" to="/">
                AwesomeTasks
              </Link>
              <a className="mobile-burger-menu" href="#" onClick={this.t.onTriggerMenu}>
                <i className="fa fa-bars" />
              </a>
              <div className="layout-nav-container" data-show-menu={this.s.showMenu} ref={this.t.layoutNavContainerRef}>
                <EventListener event="mouseup" onCalled={this.t.onWindowMouseUp} target={window} />
                {(canCan?.can("read", Organization) || canCan?.can("read", Project)) &&
                  <NavItemDropdown label={Organization.modelName().human({count: 2})}>
                    {canCan?.can("read", Organization) &&
                      <DropdownItem label={Organization.modelName().human({count: 2})} link to={Routes.organizationsPath()} />
                    }
                    {canCan?.can("read", Project) &&
                      <DropdownItem label={Project.modelName().human({count: 2})} link to={Routes.projectsPath()} />
                    }
                  </NavItemDropdown>
                }
                {canCan?.can("read", Task) &&
                  <NavItem label={Task.modelName().human({count: 2})} link to={Routes.tasksPath()} />
                }
                {canCan?.can("read", User) &&
                  <NavItem label={User.modelName().human({count: 2})} link to={Routes.usersPath()} />
                }
                {canCan?.can("read", Timelog) &&
                  <NavItemDropdown label={Timelog.modelName().human({count: 2})}>
                    <DropdownItem label={Timelog.modelName().human({count: 2})} to="/timelogs" />
                    <DropdownItem label={I18n.t("js.components.layout.index.work_status")} to="/workstatus/index" />
                  </NavItemDropdown>
                }
                {(canCan?.can("read", InvoiceGroup) || canCan?.can("read", Invoice) || canCan?.can("read", Account)) &&
                  <NavItemDropdown label={I18n.t("js.components.layout.index.invoicing")}>
                    {canCan?.can("create", Invoice) &&
                      <DropdownItem label={I18n.t("js.components.layout.index.register_bill")} to="/bills/new" />
                    }
                    {canCan?.can("read", InvoiceGroup) &&
                      <DropdownItem label={InvoiceGroup.modelName().human({count: 2})} link to={Routes.invoiceGroupsPath()} />
                    }
                    {canCan?.can("read", Invoice) &&
                      <DropdownItem label={Invoice.modelName().human({count: 2})} to="/invoices" />
                    }
                    {canCan?.can("read", Account) &&
                      <DropdownItem label={Account.modelName().human({count: 2})} to="/accounts" />
                    }
                  </NavItemDropdown>
                }
                {canCan?.can("read", UploadedFile) &&
                  <NavItem label={UploadedFile.modelName().human({count: 2})} to="/uploaded_files" />
                }
                <NavItemDropdown label={I18n.t("js.components.layout.index.profile")}>
                  <DropdownItem label={I18n.t("js.components.layout.index.your_profile")} to="/profile/index" />
                  {currentUser &&
                    <DropdownItem label={I18n.t("js.components.layout.index.sign_out")} onClick={this.t.onSignOutClicked} />
                  }
                  {!currentUser &&
                    <DropdownItem label={I18n.t("js.components.layout.index.sign_in")} to="/users/sign_in" />
                  }
                  {Locales.availableLocalesCollection().map(({label, value}) =>
                    <LocaleLink key={value} label={label} locale={value} onClick={this.t.onLocaleChanged} />
                  )}
                </NavItemDropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          {(headerTitle || controls) &&
            <div className="d-flex align-items-center">
              {headerTitle &&
                <h1 className="layout-header mb-4">
                  {headerTitle}
                </h1>
              }
              {controls &&
                <div className="ms-auto">
                  {controls}
                </div>
              }
            </div>
          }
          <Switch>
            <Case when={requireSignedIn && !currentUser}>
              <UsersSignIn />
            </Case>
            <Case when={notFound}>
              <div className="page-not-found-content">
                {I18n.t("js.components.layout.page_not_found")}
              </div>
            </Case>
            {children &&
              <Default>
                {children}
              </Default>
            }
          </Switch>
        </div>
      </div>
    )
  }

  onLocaleChanged = async (args) => {
    if (!args) return // Dunno why this happens

    const {locale} = args

    await User.changeLocale({locale})

    I18n.locale = locale
    globalEvents.emit("locale-changed")
  }

  onSignOutClicked = async () => await Devise.signOut()

  onTriggerMenu = (e) => {
    e.preventDefault()
    this.shape.set({showMenu: !this.s.showMenu})
  }

  onWindowMouseUp = (e) => {
    const {layoutNavContainerRef} = this.t

    // Close the header actions menu if clicked happened outside
    if (this.s.showMenu && layoutNavContainerRef.current && !layoutNavContainerRef.current.contains(e.target)) this.s.set({showMenu: false})
  }
}

export default compose(
  ComponentsLayout,
  (component) => withCanCan(
    component,
    [
      [Account, ["read"]],
      [Invoice, ["create", "read"]],
      [InvoiceGroup, ["read"]],
      [Organization, ["read"]],
      [Project, ["read"]],
      [Task, ["read"]],
      [Timelog, ["read"]],
      [UploadedFile, ["read"]],
      [User, ["read"]]
    ]
  ),
  (component) => withCurrentUser(component)
)
