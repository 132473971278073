import OrganizationLink from "./link"

const columns = [
  {
    attribute: "id",
    content: ({organization}) => (
      <OrganizationLink organization={organization}>
        {organization.id()}
      </OrganizationLink>
    ),
    sortKey: "id"
  },
  {
    attribute: "name",
    sortKey: "name"
  }
]

const editModelPath = ({organization}) => Routes.editOrganizationPath(organization.id())

const filterContent = ({onFilterChangedWithDelay, qParams}) =>
  <div className="row">
    <div className="col-md-6">
      <BootstrapInput
        defaultValue={qParams.name_cont_any_word}
        label={Organization.humanAttributeName("name")}
        name="name_cont_any_word"
        onChange={onFilterChangedWithDelay}
      />
    </div>
  </div>

export default function OrganizationsTable(props) {
  const {className, ...restProps} = props

  return (
    <ApiMakerTable
      appHistory={AppHistory}
      columns={columns}
      defaultParams={{s: "name asc"}}
      editModelPath={editModelPath}
      filterContent={filterContent}
      header={Organization.modelName().human({count: 2})}
      modelClass={Organization}
      select={{
        Organization: ["id", "name"]
      }}
      {...restProps}
    />
  )
}
