const columns = [
  {
    attribute: "id",
    sortKey: "id"
  },
  {
    attribute: "name",
    content: ({project}) => <ProjectLink project={project} />,
    sortKey: "name"
  },
  {
    attribute: "name",
    content: ({project}) => project.organization() && <OrganizationLink organization={project.organization()} />,
    identifier: "organization",
    label: Project.humanAttributeName("organization"),
    sortKey: "organizationName"
  },
  {
    attribute: "createdAt",
    sortKey: "createdAt",
    textRight: true
  }
]

const editModelPath = ({project}) => Routes.editProjectPath(project.id())

const filterContent = ({onFilterChangedWithDelay, qParams}) =>
  <div className="row">
    <div className="col-md-6">
      <BootstrapInput
        defaultValue={qParams.name_cont_any_word}
        label={Project.humanAttributeName("name")}
        name="name_cont_any_word"
        onChange={onFilterChangedWithDelay}
      />
    </div>
  </div>

export default function ComponentsProjectsTable(props) {
  const {className, ...restProps} = props

  return (
    <ApiMakerTable
      appHistory={AppHistory}
      className={classNames("components-projects-table", className)}
      columns={columns}
      defaultParams={{s: "name asc"}}
      editModelPath={editModelPath}
      filterContent={filterContent}
      header={Project.modelName().human({count: 2})}
      modelClass={Project}
      preloads={["organization"]}
      select={{
        Organization: ["id", "name"],
        Project: ["createdAt", "id", "name"]
      }}
      {...restProps}
    />
  )
}
