const userContent = ({projectAutoassignedUser}) => projectAutoassignedUser.user() && <UserLink user={projectAutoassignedUser.user()} />

const columns = [
  {
    attribute: "id",
    sortKey: "id"
  },
  {
    attribute: "name",
    content: userContent,
    identifier: "user",
    label: () => ProjectAutoassignedUser.humanAttributeName("user"),
    path: ["user"],
    sortKey: "userName"
  }
]

export default function ComponentsProjectAutoassignedUsersTable(props) {
  const {className, ...restProps} = props

  return (
    <ApiMakerTable
      appHistory={AppHistory}
      className={classNames("components-project-auto-assigned-users-table", className)}
      columns={columns}
      header={ProjectAutoassignedUser.modelName().human({count: 2})}
      modelClass={ProjectAutoassignedUser}
      preloads={["user"]}
      select={{
        ProjectAutoassignedUser: ["id"],
        User: ["id", "name"]
      }}
      {...restProps}
    />
  )
}
