const ComponentsLayoutSubmitButton = (props) => {
  const {children, className, ...restProps} = props

  return (
    <button
      className={classNames("components--layout-submit-button", "btn", "btn-primary", className)}
      type="submit"
      {...restProps}
    >
      {children || I18n.t("js.shared.save")}
    </button>
  )
}

export default memo(ComponentsLayoutSubmitButton)
