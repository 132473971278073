export default function ComponentsLayoutDropdownItem(props) {
  const {label, link, onClick, to, ...restProps} = props
  const onClickCallback = useCallback((e) => {
    if (onClick) {
      e.preventDefault()
      onClick()
    }
  })

  return (
    <>
      {link &&
        <Link className="components--layout--dropdown-item" onClick={onClickCallback} to={to || "#"} {...restProps}>
          {label}
        </Link>
      }
      {!link &&
        <a className="components--layout--dropdown-item" href={to || "#"} onClick={onClickCallback} {...restProps}>
          {label}
        </a>
      }
    </>
  )
}
