import Modal from "components/modal"
import UserProjectLinksForm from "./form"

export default class componentsUserProjectLinksEditModal extends BaseComponent {
  static propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    userProjectLink: PropTypes.instanceOf(UserProjectLink).isRequired
  }

  render() {
    const {className, onClose, userProjectLink, ...restProps} = this.props

    return (
      <Modal className={classNames("components-user-project-links-edit-modal", className)} onClose={onClose} {...restProps}>
        <UserProjectLinksForm onSaved={this.onSaved} userProjectLink={userProjectLink} />
      </Modal>
    )
  }

  onSaved = () => this.props.onClose()
}
