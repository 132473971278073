const ComponentsUsersSignIn = () => {
  const s = useShape()
  const {t} = useI18n({namespace: "js.components.users.sign_in"})

  s.meta.currentUser = useCurrentUser()
  s.meta.emailRef = useRef()
  s.meta.passwordRef = useRef()

  s.useStates({rememberMe: false})

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()

    const email = digg(s.m.emailRef, "current", "value")
    const password = digg(s.m.passwordRef, "current", "value")

    try {
      await Devise.signIn(email, password, {rememberMe: s.s.rememberMe, scope: "user"})
      FlashMessage.success(t(".you_were_signed_in"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }, [])

  return (
    <div className="components--users--sign-in">
      {s.m.currentUser &&
        t(".you_are_signed_in_as", {name: currentUser.name()})
      }
      {!s.m.currentUser &&
        <div className="row">
          <div className="col-md-4">
            <BootstrapCard className="mt-4" header={t(".sign_in")}>
              <form onSubmit={onSubmit}>
                <BootstrapInput
                  id="user_email"
                  inputRef={s.m.emailRef}
                  label={User.humanAttributeName("email")}
                />
                <BootstrapInput
                  className="mb-2"
                  id="user_password"
                  inputRef={s.m.passwordRef}
                  label={t("js.shared.password")}
                  type="password"
                />
                <Checkbox checked={s.s.rememberMe} label={t(".remember_me")} onChangeChecked={s.setStates.rememberMe} />
                <SubmitButton children={t(".sign_in")} className="mt-2" />
              </form>
            </BootstrapCard>
          </div>
        </div>
      }
    </div>
  )
}

export default memo(ComponentsUsersSignIn)
