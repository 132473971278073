import {incorporate} from "incorporator"
import xss from "xss"

export default class ComponentsSanitizedHtml extends BaseComponent {
  render() {
    const {html} = this.p
    const whiteList = {
      img: ["class", "data-controller"]
    }
    const defaultWhiteList = xss.whiteList
    const extendedWhiteList = incorporate(whiteList, defaultWhiteList)
    const options = {whiteList: extendedWhiteList}
    const sanitizedHtml = xss(html, options)

    return (
      <div dangerouslySetInnerHTML={{__html: sanitizedHtml}} />
    )
  }
}
