export default class ComponentsInvoiceGroupsNav extends BaseComponent {
  static propTypes = {
    invoiceGroup: PropTypes.instanceOf(InvoiceGroup).isRequired
  }

  render() {
    const {className, invoiceGroup, ...restProps} = this.props

    return (
      <ul className={classNames("nav", "nav-pills", className)} {...restProps}>
        <li className="nav-item">
          <Link className={this.navLinkClassNames("general")} to={Routes.invoiceGroupPath(invoiceGroup.id())}>
            {I18n.t("js.shared.general")}
          </Link>
        </li>
        <li className="nav-item">
          <Link className={this.navLinkClassNames("invoices")} to={Routes.invoiceGroupInvoicesPath(invoiceGroup.id())}>
            {InvoiceGroup.humanAttributeName("invoices")}
          </Link>
        </li>
      </ul>
    )
  }

  navLinkClassNames(identifier) {
    const classNames = ["nav-link"]

    if (this.props.active == identifier) {
      classNames.push("active")
    }

    return classNames.join(" ")
  }
}
