import "./style"

export default function ComponentsInfoTable(props) {
  const {children, className, controls, header, ...restProps} = props

  return (
    <table className={classNames("components-info-table", className)} {...restProps}>
      {(controls || header) &&
        <thead>
          <tr>
            <th colSpan="2">
              <div style={{display: "flex"}}>
                {header &&
                  <div style={{alignSelf: "center", marginRight: "auto"}}>
                    {header}
                  </div>
                }
                {controls &&
                  <div style={{alignSelf: "center", marginLeft: "auto"}}>
                    {controls}
                  </div>
                }
              </div>
            </th>
          </tr>
        </thead>
      }
      <tbody>
        {children}
      </tbody>
    </table>
  )
}
