import padLeft from "pad-left"

const secondsToTime = (seconds) => {
  const hours = Math.floor(seconds / 3600)

  seconds -= hours * 3600

  const minutes = Math.floor(seconds / 60)

  seconds -= minutes * 60

  return `${hours}:${padLeft(minutes, 2, 0)}`
}

export default secondsToTime
