export default class ComponentsTaskAssignedUsersTable extends BaseComponent {
  static propTypes = {
    className: PropTypes.string,
    taskId: PropTypes.string
  }

  render() {
    const {className, taskId, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components-task-assigned-users-table", className)}
        columns={this.t.columns}
        controls={this.t.controls}
        header={TaskAssignedUser.modelName().human({count: 2})}
        modelClass={TaskAssignedUser}
        preloads={["user"]}
        select={{
          TaskAssignedUser: ["id"],
          User: ["id", "name"]
        }}
        {...restProps}
      />
    )
  }

  controls = () => {
    const {taskId} = this.props

    return (
      <Link className="new-assigned-user-button" to={Routes.newTaskAssignedUserPath({task_assigned_user: {task_id: taskId}})}>
        <i className="fa fa-pencil" />
      </Link>
    )
  }

  userContent = ({taskAssignedUser}) => taskAssignedUser.user() &&
    <UserLink user={taskAssignedUser.user()} />

  columns = [
    {
      attribute: "userId",
      content: this.t.userContent,
      label: TaskAssignedUser.humanAttributeName("user"),
      sortKey: "userFirstName"
    }
  ]
}
