import HayaSelect from "haya-select/src/select"

const optionsCallback = async ({searchValue, values}) => {
  const query = Organization
    .ransack({s: "name asc"})
    .select({Organization: ["id", "name"]})
    .limit(20)

  if (searchValue) query.ransack({name_cont_any_word: searchValue})
  if (values) query.ransack({id_eq_any: values})

  const organizations = await query.toArray()

  return organizations.map((organization) => ({
    text: organization.name() || organization.id(),
    value: organization.id()
  }))
}

export default function componentsOrganizationsSelect(props) {
  const {className, ...restProps} = props

  return (
    <HayaSelect
      className={classNames("components-organizations-select", className)}
      options={optionsCallback}
      search
      {...restProps}
    />
  )
}
