export default class ComponentsLayoutControlsDestroy extends BaseComponent {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object.isRequired,
    redirectTo: PropTypes.string.isRequired
  }

  render() {
    const {className, model, redirectTo, ...restProps} = this.props

    return (
      <a className={classNames("components--layout--controls--destroy", className)} href="#" onClick={this.t.onClick} {...restProps}>
        <i className="fa fa-remove" />
        <span style={{marginLeft: "4px"}}>
          {I18n.t("js.components.layout.controls.destroy.delete")}
        </span>
      </a>
    )
  }

  onClick = async (e) => {
    e.preventDefault()

    if (!confirm(I18n.t("js.shared.are_you_sure"))) return

    try {
      await this.p.model.destroy()
      AppHistory.push(this.p.redirectTo)
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}
