import HayaSelect from "haya-select/src/select"

export default class ComponentsProjectsSelect extends BaseComponent {
  static propTypes = {
    className: PropTypes.string,
    query: PropTypes.object
  }

  render() {
    const {className, query, ...restProps} = this.props

    return (
      <HayaSelect
        className={classNames("components-projects-select", className)}
        options={this.optionsCallback}
        search
        {...restProps}
      />
    )
  }

  optionsCallback = async ({searchValue, values}) => {
    let query = this.props.query?.clone() || Project

    query = query
      .ransack({s: "name asc"})
      .select({Project: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const projects = await query.toArray()

    return projects.map((project) => ({
      text: project.name(),
      value: project.id()
    }))
  }
}
