import CloseButton from "./close-button"
import TasksProgress from "./progress"

const actionsContent = ({task}) => <CloseButton task={task} />

const editModelPath = ({task}) => Routes.editTaskPath(task.id())
const tasksIdContent = ({task}) => <TaskLink task={task}>{task.id()}</TaskLink>
const tasksNameContent = ({task}) => <TaskLink task={task} />
const tasksProjectContent = ({task}) => task.project() && <ProjectLink project={task.project()} />
const tasksStateContent = ({task}) => task.translatedState()
const columns = [
  {
    attribute: "id",
    content: tasksIdContent,
    sortKey: "id"
  },
  {
    attribute: "name",
    content: tasksNameContent,
    sortKey: "projectAndTaskName"
  },
  {
    content: ({task}) => <TasksProgress task={task} />,
    identifier: "progress",
    label: I18n.t("js.components.tasks.table.progress")
  },
  {
    attribute: "state",
    content: tasksStateContent,
    sortKey: "state"
  },
  {
    attribute: "createdAt",
    sortKey: "createdAt",
    textRight: true
  },
  {
    attribute: "name",
    content: tasksProjectContent,
    identifier: "project",
    label: Task.humanAttributeName("project"),
    path: ["project"],
    sortKey: "projectName"
  },
  {
    attribute: "timelogsTimeSecondsSum",
    content: ({task}) => (task.timelogsTimeSecondsSum() / 3600).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}),
    label: I18n.t("js.components.tasks.table.time"),
    sortKey: "timelogsTimeSecondsSum",
    textRight: true
  },
  {
    attribute: "timelogsTimeTransportSecondsSum",
    content: ({task}) => (task.timelogsTimeTransportSecondsSum() / 3600).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}),
    label: I18n.t("js.components.tasks.table.transport"),
    sortKey: "timelogsTimeTransportSecondsSum",
    textRight: true
  },
  {
    attribute: "timelogsTransportLengthSum",
    label: I18n.t("js.components.tasks.table.length"),
    sortKey: "timelogsTransportLengthSum",
    textRight: true
  }
]

const filterContent = ({qParams, onFilterChanged, onFilterChangedWithDelay}) => (
  <div className="row">
    <div className="col-lg-4 col-md-6">
      <BootstrapInput
        className="task-name-filter"
        defaultValue={qParams.name_cont_any_word}
        label={Task.humanAttributeName("name")}
        name="name_cont_any_word"
        onChange={onFilterChangedWithDelay}
      />
    </div>
    <div className="col-lg-4 col-md-6">
      <BootstrapInput
        className="task-description-filter"
        defaultValue={qParams.description_cont_any_word}
        label={Task.humanAttributeName("description")}
        name="description_cont_any_word"
        onChange={onFilterChangedWithDelay}
      />
    </div>
    <div className="col-lg-4 col-md-6">
      <BootstrapSelect
        defaultValue={qParams.state_eq_any}
        label={Task.humanAttributeName("state")}
        multiple
        name="state_eq_any"
        onChange={onFilterChanged}
        options={TranslatedCollections.get(Task, "state").map((option) => [digg(option, "translation"), digg(option, "value")])}
      />
    </div>
  </div>
)

export default function ComponentsTasksTable(props) {
  const {className, ...restProps} = props

  return (
    <ApiMakerTable
      abilities={{Task: ["stateEventClose"]}}
      actionsContent={actionsContent}
      appHistory={AppHistory}
      className={classNames("components--tasks--table", className)}
      columns={columns}
      defaultParams={{s: "created_at asc"}}
      destroyEnabled
      editModelPath={editModelPath}
      filterContent={filterContent}
      header={Task.modelName().human({count: 2})}
      modelClass={Task}
      preloads={["project"]}
      select={{
        Project: ["id", "name"],
        Task: [
          "childrenCount",
          "childrenDoneCount",
          "createdAt",
          "id",
          "name",
          "progress",
          "timelogsTimeSecondsSum",
          "timelogsTimeTransportSecondsSum",
          "timelogsTransportLengthSum",
          "translatedState"
        ]
      }}
      {...restProps}
    />
  )
}
