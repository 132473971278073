import HayaSelect from "haya-select/src/select"

export default class ComponentsTaskTypesSelect extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    query: PropTypes.object
  }

  render() {
    const {className, query, ...restProps} = this.props

    return (
      <HayaSelect
        className={classNames("components-task-types-select", className)}
        options={this.optionsCallback}
        search
        {...restProps}
      />
    )
  }

  optionsCallback = async ({searchValue, values}) => {
    let query = this.props.query?.clone() || TaskType

    query = query
      .ransack({s: "first_name asc"})
      .select({TaskType: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({first_name_or_last_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const taskTypes = await query.toArray()

    return taskTypes.map((taskType) => ({
      text: taskType.name(),
      value: taskType.id()
    }))
  }
}
