export default class ComponentsLayoutControlsBase extends BaseComponent {
  static defaultProps = {
    to: "#"
  }

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.string,
    to: PropTypes.string
  }

  render() {
    const {children, className, icon, to, ...restProps} = this.props

    return (
      <Link className={classNames("components--layout--controls--base", className)} to={to} {...restProps}>
        <i className={`fa fa-${icon}`} />
        <span style={{marginLeft: "4px"}}>
          {children}
        </span>
      </Link>
    )
  }
}
