import {Controller} from "stimulus"
import {createRoot} from "react-dom/client"

export default class ReactController extends Controller {
  connect() {
    const componentPath = digg(this, "element", "dataset", "component")
    const propsAsJson = this.element.dataset.props

    let componentProps

    if (propsAsJson) componentProps = JSON.parse(propsAsJson)

    const Component = require(`components/${componentPath}`).default
    const root = createRoot(this.element)

    root.render(<Component {...componentProps} />)
  }
}
