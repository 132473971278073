import ControlsBase from "components/layout/controls/base"
import TimeSecondsInput from "components/timelogs/time-seconds-input"

export default class ComponentsTasksAddDailyWorkButton extends BaseComponent {
  static propTypes = {
    className: PropTypes.instanceOf(String),
    task: PropTypes.instanceOf(Task).isRequired
  }

  shape = new Shape(this, {
    modalOpen: false,
    timelog: undefined
  })

  render() {
    const {className, task, ...restProps} = this.props
    const {timelog} = this.s

    return (
      <>
        {this.s.modalOpen &&
          <Modal onRequestClose={this.t.onRequestModalClose}>
            <form onSubmit={this.t.onSubmit}>
              <TimeSecondsInput attributeName="time" timelog={timelog} />
              <BootstrapInput attribute="comment" model={timelog} type="textarea" />
              <SubmitButton className="mt-2" />
            </form>
          </Modal>
        }
        <ControlsBase
          className={classNames("components--tasks--add-daily-work-button", className)}
          icon="person-digging"
          onClick={this.t.onClick}
          {...restProps}
        >
          {I18n.t("js.components.tasks.add_daily_work_button.add_daily_work")}
        </ControlsBase>
      </>
    )
  }

  onClick = (e) => {
    e.preventDefault()

    this.shape.set({
      modalOpen: true,
      timelog: new Timelog()
    })
  }

  onRequestModalClose = () => this.shape.set({modalOpen: false})

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")

    try {
      await this.p.task.addDailyWork(form)
      FlashMessage.success(I18n.t("js.components.tasks.add_daily_work_button.the_work_was_saved"))

      this.shape.set({
        modalOpen: false,
        timelog: undefined
      })
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}
