const columns = [
  {
    attribute: "id",
    sortKey: "id"
  },
  {
    attribute: "name",
    content: ({userProjectLink}) => userProjectLink.user() && <UserLink user={userProjectLink.user()} />,
    identifier: "user",
    label: () => UserProjectLink.humanAttributeName("user"),
    path: ["user"],
    sortKey: "userName"
  }
]

export default function ComponentsUserProjectLinksTable(props) {
  const {className, ...restProps} = props

  return (
    <ApiMakerTable
      appHistory={AppHistory}
      className={classNames("components--user-project-links--table", className)}
      columns={columns}
      defaultParams={{s: "user_name asc"}}
      header={UserProjectLink.modelName().human({count: 2})}
      modelClass={UserProjectLink}
      preloads={["user"]}
      select={{User: ["id"]}}
      {...restProps}
    />
  )
}
