import ProjectsSelect from "components/projects/select"
import TaskTypesSelect from "components/task-types/select"

export default class ComponentsTasksForm extends BaseComponent {
  static propTypes = {
    className: PropTypes.string,
    onSaved: PropTypes.func,
    task: PropTypes.instanceOf(Task).isRequired
  }

  priorityCollection = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  projectsQuery = Project.ransack({active_or_id_eq: [this.props.task.projectId()]})

  shape = new Shape(this, {
    projectId: this.props.task.projectId(),
    taskTypesQuery: TaskType.ransack({project_id_eq: this.props.task.projectId()})
  })

  stateOptions = TranslatedCollections
    .get(Task, "state")
    .map((option) => [digg(option, "translation"), digg(option, "value")])

  render() {
    const {onSubmit, priorityCollection, projectsQuery, stateOptions} = this.t
    const {className, onSaved, task, ...restProps} = this.props
    const {projectId, taskTypesQuery} = this.s

    return (
      <form className={classNames("components--tasks--form", className)} onSubmit={onSubmit} {...restProps}>
        {task.isNewRecord() &&
          <input name="task[user_id]" type="hidden" value={Devise.currentUser()?.id()} />
        }
        <div className="row">
          <div className="col-md-8">
            <BootstrapInput attribute="name" model={task} />
          </div>
          <div className="col-md-2">
            <BootstrapSelect attribute="state" includeBlank model={task} options={stateOptions} />
          </div>
          <div className="col-md-2">
            <BootstrapSelect attribute="priority" model={task} options={priorityCollection} />
          </div>
          <div className="col-md-6">
            <BootstrapSelect attribute="projectId" label={Task.humanAttributeName("project")} model={task}>
              <ProjectsSelect
                attribute="projectId"
                model={task}
                onChange={this.onProjectChanged}
                query={projectsQuery}
              />
            </BootstrapSelect>
          </div>
          <div className="col-md-6">
            {projectId &&
              <BootstrapSelect attribute="taskTypeId" label={Task.humanAttributeName("taskType")} model={task}>
                <TaskTypesSelect
                  attribute="taskTypeId"
                  key={`task-type-${projectId}`}
                  model={task}
                  query={taskTypesQuery}
                />
              </BootstrapSelect>
            }
          </div>
          <div className="col-md-12 mt-3">
            <BootstrapInput attribute="description" className="w-100" data-controller="ckeditor" model={task} type="textarea" />
          </div>
        </div>

        <SubmitButton className="mt-3" />
      </form>
    )
  }

  onProjectChanged = ({options}) => {
    const value = dig(options, 0, "value")

    this.shape.set({
      projectId: value,
      taskTypesQuery: TaskType.ransack({project_id_eq: value})
    })
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const {onSaved} = this.props
    const {task} = this.p
    const form = digg(e, "target")

    try {
      await task.saveRaw(form)
      FlashNotifications.success(I18n.t("js.components.tasks.form.the_task_was_saved"))
      if (onSaved) onSaved()
    } catch (error) {
      FlashNotifications.errorResponse(error)
    }
  }
}
