import Modal from "components/modal"
import ProjectAutoassignedUsersForm from "./form"

export default class componentsProjectAutoassignedUsersEditModal extends BaseComponent {
  static propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    projectAutoassignedUser: PropTypes.instanceOf(ProjectAutoassignedUser).isRequired
  }

  render() {
    const {className, onClose, projectAutoassignedUser, ...restProps} = this.props

    return (
      <Modal className={classNames("components-project-autoassigned-users-edit-modal", className)} onClose={onClose} {...restProps}>
        <ProjectAutoassignedUsersForm onSaved={this.onSaved} projectAutoassignedUser={projectAutoassignedUser} />
      </Modal>
    )
  }

  onSaved = () => this.props.onClose()
}
