import BodyPortal from "components/layout/body-portal"

export default class ComponentsModalIndex extends BaseComponent {
  modalRef = React.createRef()

  render() {
    const {children, className, onClose, ...restProps} = this.props

    return (
      <BodyPortal>
        <EventListener event="click" onCalled={this.onWindowClicked} target={window} />
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            height: "100%",
            padding: "50px",
            position: "fixed",
            width: "100%",
            zIndex: 9999
          }}
        >
          <div
            className={classNames("components-modal", className)}
            ref={this.t.modalRef}
            style={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "50px"
            }}
            {...restProps}
          >
            {children}
          </div>
        </div>
      </BodyPortal>
    )
  }

  onWindowClicked = (e) => {
    // If clicked outside the modal content
    if (!this.modalRef.current.contains(e.target)) {
      e.preventDefault()
      e.stopPropagation()

      this.props.onClose()
    }
  }
}
