export default class ComponentsLayoutButton extends BaseComponent {
  static defaultProps = {
    disabled: false
  }

  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func
  }

  render() {
    const {children, className, disabled, icon, onClick, ...restProps} = this.props

    return (
      <a className={classNames("components--layout--button", className)} data-disabled={disabled} href="#" onClick={onClick} {...restProps}>
        <i className={`fa fa-${icon}`} />
        <span className="button-label" style={{marginLeft: "4px"}}>
          {children}
        </span>
      </a>
    )
  }

  onClick = (e) => {
    const {onClick} = this.props

    if (onClick) {
      e.preventDefault()

      if (!this.p.disabled) {
        onClick()
      }
    }
  }
}
