export default function ComponentsOrganizationsLink(props) {
  const {children, className, organization, ...restProps} = props

  return (
    <Link
      className={classNames("components-organizations-link", className)}
      data-organization-id={organization.id()}
      to={Routes.organizationPath(organization.id())}
      {...restProps}
    >
      {children || organization.name()}
    </Link>
  )
}
