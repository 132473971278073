import AddDailyWorkButton from "components/tasks/add-daily-work-button"
import DestroyControl from "components/layout/controls/destroy"
import EditControl from "components/layout/controls/edit"

export default (props) => {
  const {task} = digs(props, "task")

  return (
    <>
      <AddDailyWorkButton task={task} />
      {task?.can("edit") &&
        <EditControl to={Routes.editTaskPath(task.id())} />
      }
      {task?.can("destroy") &&
        <DestroyControl model={task} redirectTo={Routes.projectPath(task.projectId())} />
      }
    </>
  )
}
