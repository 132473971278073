import "./style"

export default function ComponentsLayoutNavItem(props) {
  const {label, link, to, ...restProps} = props

  return (
    <>
      {link &&
        <Link className="components--layout--nav-item" to={to} {...restProps}>
          {label}
        </Link>
      }
      {!link &&
        <a className="components--layout--nav-item" href={to} {...restProps}>
          {label}
        </a>
      }
    </>
  )
}
