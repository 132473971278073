import secondsToTime from "components/timelogs/seconds-to-time"

export default class ComponentsTimelogsTimeSecondsInput extends BaseComponent {
  static propTypes = {
    attributeName: PropTypes.string.isRequired,
    timelog: PropTypes.instanceOf(Timelog).isRequired
  }

  timeSecondsRef = React.createRef()

  render() {
    const {attributeName, timelog, ...restProps} = this.props
    const attributeNameUnderscore = inflection.underscore(attributeName)
    const timeSeconds = timelog[`${attributeName}Seconds`]()

    return (
      <>
        <input
          id={`timelog_${attributeNameUnderscore}_seconds`}
          name={`timelog[${attributeNameUnderscore}_seconds]`}
          ref={this.t.timeSecondsRef}
          type="hidden"
          value={timeSeconds === null ? "" : timeSeconds}
        />
        <BootstrapInput
          defaultValue={secondsToTime(timeSeconds)}
          id={`timelog_${attributeNameUnderscore}`}
          onChange={this.t.onTimeChanged}
          {...restProps}
        />
      </>
    )
  }

  onTimeChanged = (e) => {
    const value = digg(e, "target", "value")
    const match = value.match(/^(\d+):(\d+)$/)

    if (!match) {
      this.timeSecondsRef.current.value = ""

      return
    }

    const hours = match[1]
    const minutes = match[2]
    let seconds = hours * 3600

    seconds += minutes * 60

    this.timeSecondsRef.current.value = seconds
  }
}
