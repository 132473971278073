import ProjectsSelect from "components/projects/select"
import UsersSelect from "components/users/select"

export default class ComponentsProjectAutoassignedUsersForm extends BaseComponent {
  static propTypes = {
    onSaved: PropTypes.func,
    projectAutoassignedUser: PropTypes.instanceOf(ProjectAutoassignedUser).isRequired
  }

  render() {
    const {className, onSaved, projectAutoassignedUser, ...restProps} = this.props

    return (
      <form className={classNames("components-project-autoassigned-users-form", className)} onSubmit={this.onSubmit} {...restProps}>
        <BootstrapSelect attribute="project" model={projectAutoassignedUser}>
          <ProjectsSelect attribute="projectId" model={projectAutoassignedUser} />
        </BootstrapSelect>
        <BootstrapSelect attribute="user" model={projectAutoassignedUser}>
          <UsersSelect attribute="userId" model={projectAutoassignedUser} />
        </BootstrapSelect>
        <SubmitButton className="submit-project-autoassigned-user-button" />
      </form>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const {onSaved} = this.props
    const {projectAutoassignedUser} = this.p
    const form = digg(e, "target")

    try {
      await projectAutoassignedUser.saveRaw(form)
      FlashNotifications.success(I18n.t("js.components.project_autoassigned_users.form.the_auto_assignment_was_saved"))
      if (onSaved) onSaved()
    } catch (error) {
      FlashNotifications.errorResponse(error)
    }
  }
}
