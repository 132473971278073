export default class ComponentsCommentsContainer extends BaseComponent {
  static propTypes = {
    className: PropTypes.string,
    resource: PropTypes.object.isRequired
  }

  shape = new Shape(this, {
    comments: undefined
  })

  componentDidMount() {
    this.loadComments()
  }

  async loadComments() {
    const comments = await Comment
      .ransack({
        resource_id_eq: this.p.resource.id(),
        resource_type_eq: this.p.resource.modelClassData().name,
        s: "created_at desc"
      })
      .preload("user")
      .select({
        Comment: ["comment", "createdAt", "id"],
        User: ["id", "name"]
      })
      .toArray()

    this.shape.set({comments})
  }

  render() {
    const {className, resource, ...restProps} = this.props

    return (
      <div className={classNames("components--comments--container", className)} {...restProps}>
        {this.s.comments?.map((comment) =>
          <div className="comment-card" data-comment-id={comment.id()} key={comment.id()} style={{marginBottom: "15px"}}>
            <BootstrapCard>
              <div style={{display: "flex"}}>
                <div>
                  <UserLink user={comment.user()} />
                </div>
                <div style={{marginLeft: "auto"}}>
                  {I18n.l("time.formats.default", comment.createdAt())}
                </div>
              </div>
              <SanitizedHtml html={comment.comment()} />
            </BootstrapCard>
          </div>
        )}
        {this.s.comments?.length === 0 &&
          <div>
            {I18n.t("js.components.comments.container.no_comments_have_been_written")}
          </div>
        }
      </div>
    )
  }
}
