export default class ComponentsTaskTypesTable extends BaseComponent {
  static propTypes = {
    className: PropTypes.string
  }

  render() {
    const {className, ...restProps} = this.props

    return (
      <ApiMakerTable
        appHistory={AppHistory}
        className={classNames("components-task-types-table", className)}
        columns={this.columns}
        defaultParams={{s: "current_translation_name asc"}}
        editModelPath={this.editModelPath}
        header={TaskType.modelName().human({count: 2})}
        modelClass={TaskType}
        {...restProps}
      />
    )
  }

  columns = [
    {
      attribute: "id",
      sortKey: "id"
    },
    {
      attribute: "name",
      sortKey: "currentTranslationName"
    }
  ]

  editModelPath = ({taskType}) => Routes.editTaskTypePath(taskType.id())
}
