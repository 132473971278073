import "shared/devise"
import {Container} from "flash-notifications"
import {createRoot} from "react-dom/client"
import Router from "@kaspernj/api-maker/src/router"
import React from "react" // eslint-disable-line sort-imports
import withPathname from "on-location-changed/src/with-path"


// Set global.applicationHistory so that on-location-changed will listen on it
import {callbacksHandler} from "on-location-changed/src/callbacks-handler" // eslint-disable-line sort-imports
import history from "shared/history"

callbacksHandler.connectReactRouterHistory(history)

const LayoutNotFoundPage = React.lazy(() => import(/* webpackChunkName: "not-found-page" */ "components/layout/not-found-page"))
const requireComponent = ({routeDefinition}) => React.lazy(() => import(/* webpackChunkName: "[request]" */ `routes/${routeDefinition.component}`))
const routeDefinitions = require("shared/route-definitions.json")
const RouterWithLocation = withPathname(Router)

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")
  const root = createRoot(reactRoot)

  root.render(
    <>
      <Container />
      <RouterWithLocation
        history={AppHistory}
        notFoundComponent={LayoutNotFoundPage}
        requireComponent={requireComponent}
        routeDefinitions={routeDefinitions}
        routes={Routes}
      />
    </>
  )
})
