export default class ComponentsUsersTable extends BaseComponent {
  static propTypes = {
    className: PropTypes.string,
    onSelected: PropTypes.func
  }

  render() {
    const {className, ...restProps} = this.props

    return (
      <ApiMakerTable
        actionsContent={this.actionsContent}
        appHistory={AppHistory}
        className={classNames("components-users-table", className)}
        columns={this.columns}
        defaultParams={{s: "name asc"}}
        editModelPath={this.editModelPath}
        filterContent={this.filterContent}
        header={User.modelName().human({count: 2})}
        modelClass={User}
        {...restProps}
      />
    )
  }

  actionsContent = ({user}) => {
    const {onSelected} = this.props

    return (
      <>
        {onSelected &&
          <a className="btn btn-default btn-sm user-select-button" href="#" onClick={(e) => this.onSelectClicked(e, user)}>
            {I18n.t("js.components.users.table.select")}
          </a>
        }
      </>
    )
  }

  idContent = ({user}) => <UserLink user={user}>{user.id()}</UserLink>

  columns = [
    {
      attribute: "id",
      content: this.idContent,
      sortKey: "id"
    },
    {
      attribute: "name",
      sortKey: "name"
    },
    {
      attribute: "firstName",
      sortKey: "firstName"
    },
    {
      attribute: "lastName",
      sortKey: "lastName"
    },
    {
      attribute: "email",
      sortKey: "email"
    }
  ]

  editModelPath = ({user}) => Routes.editUserPath(user.id())

  filterContent = ({qParams, onFilterChangedWithDelay}) =>
    <div className="row">
      <div className="col-lg-4 col-md-6">
        <BootstrapInput
          attribute="firstName"
          defaultValue={qParams.first_name_cont}
          label={User.humanAttributeName("firstName")}
          name="first_name_cont"
          onChange={onFilterChangedWithDelay}
        />
      </div>
      <div className="col-lg-4 col-md-6">
        <BootstrapInput
          attribute="lastName"
          defaultValue={qParams.last_name_cont}
          label={User.humanAttributeName("lastName")}
          name="last_name_cont"
          onChange={onFilterChangedWithDelay}
        />
      </div>
      <div className="col-lg-4 col-md-6">
        <BootstrapInput
          attribute="email"
          defaultValue={qParams.email_cont}
          label={User.humanAttributeName("email")}
          name="email_cont"
          onChange={onFilterChangedWithDelay}
        />
      </div>
    </div>

  onSelectClicked = (e, user) => {
    e.preventDefault()

    const {onSelected} = this.p

    onSelected({user})
  }
}
