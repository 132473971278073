export default class Locales {
  static availableLocalesCollection() {
    return [{"label":"Danish","value":"da"},{"label":"English","value":"en"}]
  }

  static availableLocales() {
    return this.availableLocalesCollection().map((localeData) => digg(localeData, "value"))
  }

  static labelForLocale(locale) {
    for (const localeData of this.availableLocalesCollection()) {
      const {label, value} = digs(localeData, "label", "value")

      if (locale == value) return label
    }

    throw new Error(`Unknown locale: ${locale}`)
  }
}
