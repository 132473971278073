export default class ComponentsOrganizationsForm extends BaseComponent {
  static propTypes = {
    className: PropTypes.string,
    onSaved: PropTypes.func,
    organization: PropTypes.instanceOf(Organization).isRequired
  }

  render() {
    const {className, onSaved, organization, ...restProps} = this.props

    return (
      <form
        className={classNames("components-organizations-form", className)}
        onSubmit={this.onSubmit}
        {...restProps}
      >
        <div className="row">
          <div className="col-md-4">
            <BootstrapInput attribute="name" model={organization} />
          </div>
          <div className="col-md-4">
            <BootstrapInput attribute="email" model={organization} />
          </div>
          <div className="col-md-4">
            <BootstrapInput attribute="vatNo" model={organization} />
          </div>
          <div className="col-md-12">
            <BootstrapInput attribute="paymentInfo" model={organization} type="textarea" />
          </div>
          <div className="col-md-4">
            <BootstrapCheckbox attribute="customer" model={organization} />
          </div>
          <div className="col-md-4">
            <BootstrapCheckbox attribute="creditor" model={organization} />
          </div>
          <div className="col-md-4" />
          <div className="col-md-4">
            <BootstrapInput attribute="deliveryAddress" model={organization} />
          </div>
          <div className="col-md-2">
            <BootstrapInput attribute="deliveryZipCode" model={organization} />
          </div>
          <div className="col-md-3">
            <BootstrapInput attribute="deliveryCity" model={organization} />
          </div>
          <div className="col-md-3">
            <BootstrapSelect attribute="deliveryCountry" model={organization}>
              <CountrySelect attribute="deliveryCountry" className="form-control" includeBlank model={organization} />
            </BootstrapSelect>
          </div>
          <div className="col-md-4">
            <BootstrapInput attribute="invoiceAddress" model={organization} />
          </div>
          <div className="col-md-2">
            <BootstrapInput attribute="invoiceZipCode" model={organization} />
          </div>
          <div className="col-md-3">
            <BootstrapInput attribute="invoiceCity" model={organization} />
          </div>
          <div className="col-md-3">
            <BootstrapSelect attribute="invoiceCountry" model={organization}>
              <CountrySelect attribute="invoiceCountry" className="form-control" includeBlank model={organization} />
            </BootstrapSelect>
          </div>
        </div>
        <SubmitButton />
      </form>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const {organization} = this.p
    const {onSaved} = this.props

    try {
      await organization.saveRaw(form)

      FlashNotifications.success(I18n.t("js.components.organizations.form.the_organization_was_saved"))

      if (onSaved) onSaved()
    } catch (error) {
      FlashNotifications.errorResponse(error)
    }
  }
}
