import {Text, View} from "react-native"

const ComponentsUtilsFrontValidationErrors = ({validationErrors}) =>
  <View>
    <Text dataSet={{class: "validation-errors"}} style={{color: "red"}}>
      {validationErrors.map((validationError) => validationError.getErrorMessages()).flat().join(". ")}
    </Text>
  </View>

export default memo(ComponentsUtilsFrontValidationErrors)
