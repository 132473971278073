export default class BaseComponent extends React.PureComponent {
  originalComponentDidMount = this.componentDidMount
  originalRender = this.render
  p = fetchingObject(() => this.props)
  s = fetchingObject(() => this.shape || this.state)
  t = fetchingObject(this)

  render = () =>
    <>
      <EventEmitterListener event="locale-changed" events={globalEvents} onCalled={this.onLocaleChanged} />
      <EventEmitterListener event="onDeviseSignIn" events={Devise.events()} onCalled={this.onDeviseSignIn} />
      <EventEmitterListener event="onDeviseSignOut" events={Devise.events()} onCalled={this.onDeviseSignOut} />
      {this.originalRender()}
    </>

  componentDidMount = () => {
    if (!this.shape) this.shape = new Shape(this, {})

    this.shape.currentUser = Devise.currentUser()
    this.shape.currentLocale = I18n.locale

    if (this.originalComponentDidMount) this.originalComponentDidMount()
  }

  onDeviseSignIn = () => {
    globalEvents.emit("reloadModel")

    this.shape.set({
      currentUser: Devise.currentUser()
    })
  }

  onDeviseSignOut = () => {
    globalEvents.emit("reloadModel")

    this.shape.set({
      currentUser: undefined
    })
  }

  onLocaleChanged = () => {
    this.shape.set({
      currentLocale: I18n.locale
    })
  }
}
