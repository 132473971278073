import {Pressable, Text, View} from "react-native"
import ValidationErrors from "./validation-errors"

const ComponentsCheckbox = ({checked, dataSet, fontStyle, inputName, label, onChange, onChangeChecked, style, value}) => {
  const s = useShape({checked, label, onChange, onChangeChecked, value})
  const {validationErrors} = useValidationErrors((validationError) => validationError.inputName == inputName)

  const onRadioButtonPressed = useCallback(() => {
    const newChecked = !s.p.checked

    if (s.props.onChange) {
      s.p.onChange({
        checked: newChecked,
        label: s.p.label,
        value: s.p.value
      })
    }

    if (s.props.onChangeChecked) {
      s.props.onChangeChecked(newChecked)
    }
  }, [])

  const pressableStyle = Object.assign({}, style, {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  })

  return (
    <View>
      <Pressable dataSet={dataSet} onPress={onRadioButtonPressed} style={pressableStyle}>
        <View
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 16,
            height: 16,
            backgroundColor: "#fff",
            border: "1px solid #adaeae",
            borderRadius: 2
          }}
        >
          {checked &&
            <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
              {/* eslint-disable-next-line max-len */}
              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
            </svg>
          }
        </View>
        {label &&
          <View style={{marginLeft: 7}}>
            <Text style={fontStyle}>
              {label}
            </Text>
          </View>
        }
      </Pressable>
      {validationErrors.length > 0 &&
        <ValidationErrors validationErrors={validationErrors} />
      }
    </View>
  )
}

ComponentsCheckbox.propTypes = PropTypesExact({
  checked: PropTypes.bool.isRequired,
  dataSet: PropTypes.object,
  fontStyle: PropTypes.object,
  inputName: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onChangeChecked: PropTypes.func,
  style: PropTypes.object,
  value: PropTypes.any
})

export default memo(ComponentsCheckbox)
