const Config = require("@kaspernj/api-maker/src/config").default
const hayaSelectFiles = require.context("../../../config/locales/haya_select", true, /^(.+)\.yml$/)
const I18nOnSteroids = require("i18n-on-steroids").default
const i18n = new I18nOnSteroids()
const baseFiles = require.context("../../../config/locales", true, /^.\/([a-z]+)\.yml$/)
const dateTimeFiles = require.context("../../../config/locales/awesome_translations/date_time", true, /^(.+)\.yml$/)
const jsFiles = require.context("../../../config/locales/awesome_translations/js", true, /^(.+)\.yml$/)
const modelFiles = require.context("../../../config/locales/awesome_translations/models", true, /^(.+)\.yml$/)
const numbersFiles = require.context("../../../config/locales/awesome_translations/numbers", true, /^(.+)\.yml$/)

i18n.scanRequireContext(baseFiles)
i18n.scanRequireContext(dateTimeFiles)
i18n.scanRequireContext(hayaSelectFiles)
i18n.scanRequireContext(jsFiles)
i18n.scanRequireContext(modelFiles)
i18n.scanRequireContext(numbersFiles)

const locale = document.querySelector("html").getAttribute("lang")

i18n.setLocale(locale)
i18n.setLocaleOnStrftime()

Config.setI18n(i18n)

module.exports = i18n
