import "./style"
import BodyPortal from "components/layout/body-portal"

export default class ComponentsModal extends BaseComponent {
  static defaultProps = {
    active: true
  }

  static propTypes = {
    active: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired
  }

  cardRef = React.createRef()

  render() {
    const {cardRef} = this.t
    const {active, children, className, onRequestClose, ...restProps} = this.props

    if (!active) return null

    return (
      <BodyPortal>
        <EventListener event="keyup" onCalled={this.t.onWindowKeyUp} target={window} />
        <EventListener event="mouseup" onCalled={this.t.onWindowMouseUp} target={window} />
        <div className={classNames("components--layout--modal", className)} {...restProps}>
          <BootstrapCard cardRef={cardRef} className="modal-card">
            {children}
          </BootstrapCard>
        </div>
      </BodyPortal>
    )
  }

  onWindowKeyUp = (e) => {
    const {keyCode} = digs(e, "keyCode")

    if (keyCode == 27) { // Escape key
      e.preventDefault()
      this.props.onRequestClose()
    }
  }

  onWindowMouseUp = (e) => {
    const {cardRef} = this.t

    if (cardRef.current && !cardRef.current.contains(e.target)) this.props.onRequestClose()
  }
}
