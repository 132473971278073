const ActualApiMakerTable = React.lazy(() => import("@kaspernj/api-maker/src/table/table"))

class SharedApiMakerTable extends BaseComponent {
  render() {
    const {currentUser, ...restProps} = this.props

    return (
      <>
        {currentUser &&
          <ActualApiMakerTable
            currentUser={currentUser}
            defaultDateFormatName="date.formats.short"
            defaultDateTimeFormatName="time.formats.short"
            {...restProps}
          />
        }
        {!currentUser &&
          <div>
            Need user to view this table
          </div>
        }
      </>
    )
  }
}

export default withCurrentUser(SharedApiMakerTable)
