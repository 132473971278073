const didUserChange = (prevUser, currentUser) => {
  if (!prevUser && currentUser) return true
  if (prevUser && !currentUser) return true
  if (prevUser?.id() != currentUser?.id()) return true

  return false
}

export default class PageComponent extends BaseComponent {
  currentUser = Devise.currentUser()
  pageComponentOriginalRender = this.render
  pageComponentOriginalComponentDidMount = this.componentDidMount

  componentDidMount = () => {
    if (this.loadPage) this.loadPage()
    if (this.pageComponentOriginalComponentDidMount) this.pageComponentOriginalComponentDidMount()
  }

  render = () =>
    <>
      <EventEmitterListener event="onDeviseSignIn" events={Devise.events()} onCalled={this.onDeviseSigned} />
      <EventEmitterListener event="onDeviseSignOut" events={Devise.events()} onCalled={this.onDeviseSigned} />
      {this.pageComponentOriginalRender()}
    </>

  onDeviseSigned = () => {
    const currentUser = Devise.currentUser()

    if (didUserChange(this.currentUser, currentUser)) {
      globalEvents.emit("reloadModel")
      this.currentUser = currentUser

      if (this.loadPage) {
        // Use setTimeout to place at the end of the event queue so withCurrentUser will run first
        setTimeout(() => this.loadPage(), 5) // Firefox doesn't work with 0
      }
    }
  }
}
