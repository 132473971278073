export default class ComponentsLayoutControlsEdit extends BaseComponent {
  render() {
    const {className, to, ...restProps} = this.props

    return (
      <Link className={classNames("components--layout--controls--edit", className)} to={to} {...restProps}>
        <i className="fa fa-edit" />
        <span style={{marginLeft: "4px"}}>
          {I18n.t("js.components.layout.controls.edit.edit")}
        </span>
      </Link>
    )
  }
}
