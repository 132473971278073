const idContent = ({invoice}) => <InvoiceLink children={invoice.id()} invoice={invoice} />

const columns = [
  {
    attribute: "id",
    content: idContent,
    sortKey: "id"
  }
]

export default class ComponentsInvoicesTable extends BaseComponent {
  render() {
    const {className, ...restProps} = this.props

    return (
      <ApiMakerTable
        appHistory={AppHistory}
        className={classNames("components-invoices-table", className)}
        columns={columns}
        defaultParams={{s: "id desc"}}
        editModelPath={this.editModelPath}
        header={Invoice.modelName().human({count: 2})}
        modelClass={Invoice}
        {...restProps}
      />
    )
  }

  editModelPath = ({invoice}) => `/invoices/${invoice.id()}/edit`
}
