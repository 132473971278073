import UsersTable from "components/users/table"

export default class ComponentsUsersSearch extends BaseComponent {
  static propTypes = {
    selectFunction: PropTypes.string
  }

  render() {
    const {selectFunction} = this.props

    return (
      <div className="components-users-search">
        <UsersTable onSelected={selectFunction && this.onUserSelected} />
      </div>
    )
  }

  onUserSelected = ({user}) => {
    const {selectFunction} = this.p

    eval(`${selectFunction}(${user.id()})`) // eslint-disable-line no-eval
  }
}
