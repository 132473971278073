export default class ComponentsCommentsNewCommentButton extends BaseComponent {
  render() {
    const {className, resource, ...restProps} = this.props

    return (
      <Link
        className={classNames("components--comments--new-comment-button", className)}
        to={Routes.newCommentPath({comment: {resource_id: resource.id(), resource_type: resource.modelClassData().name}})}
        {...restProps}
      >
        <i className="fa fa-plus" />
        <span className="ml-1">
          {I18n.t("js.components.comments.new_comment_button.new_comment")}
        </span>
      </Link>
    )
  }
}
