import secondsToTime from "components/timelogs/seconds-to-time"

const dateContent = ({timelog}) => I18n.l("date.formats.default", timelog.date())
const timeContent = ({timelog}) => secondsToTime(timelog.timeSeconds())

const columns = [
  {
    attribute: "id",
    content: ({timelog}) => <TimelogLink children={timelog.id()} timelog={timelog} />,
    sortKey: "id"
  },
  {
    attribute: "name",
    content: ({timelog}) => timelog.task() && <TaskLink task={timelog.task()} />,
    label: Timelog.humanAttributeName("task"),
    path: ["task"],
    sortKey: "taskName"
  },
  {
    attribute: "timeSeconds",
    content: timeContent,
    label: I18n.t("js.components.timelogs.table.time"),
    sortKey: "timeSeconds",
    textRight: true
  },
  {
    attribute: "date",
    content: dateContent,
    sortKey: "date",
    textRight: true
  },
  {
    attribute: "comment",
    content: ({timelog}) => (
      <div style={{whiteSpace: "pre-line"}}>
        {timelog.comment()}
      </div>
    ),
    sortKey: "comment"
  }
]

const editModelPath = ({timelog}) => Routes.editTimelogPath(timelog.id())

export default function ComponentsTimelogsTable(props) {
  const {className, ...restProps} = props

  return (
    <ApiMakerTable
      appHistory={AppHistory}
      className={classNames("components--timelogs--table", className)}
      columns={columns}
      defaultParams={{s: "created_at desc"}}
      editModelPath={editModelPath}
      header={Timelog.modelName().human({count: 2})}
      modelClass={Timelog}
      preloads={["task"]}
      select={{
        Task: ["id", "name"],
        Timelog: ["comment", "date", "id", "timeSeconds"]
      }}
      {...restProps}
    />
  )
}
