import ProjectsSelect from "components/projects/select"
import UsersSelect from "components/users/select"

export default class ComponentsUserProjectLinksForm extends BaseComponent {
  static propTypes = {
    onSaved: PropTypes.func,
    userProjectLink: PropTypes.instanceOf(UserProjectLink).isRequired
  }

  render() {
    const {className, onSaved, userProjectLink, ...restProps} = this.props

    return (
      <form className={classNames("components-user-project-links-form", className)} onSubmit={this.onSubmit} {...restProps}>
        <BootstrapSelect attribute="project" model={userProjectLink}>
          <ProjectsSelect attribute="projectId" model={userProjectLink} />
        </BootstrapSelect>
        <BootstrapSelect attribute="user" model={userProjectLink}>
          <UsersSelect attribute="userId" model={userProjectLink} />
        </BootstrapSelect>
        <SubmitButton className="submit-user-project-link-button" />
      </form>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const {onSaved} = this.props
    const {userProjectLink} = this.p
    const form = digg(e, "target")

    try {
      await userProjectLink.saveRaw(form)
      FlashNotifications.success(I18n.t("js.components.user_project_links.form.the_assignment_was_saved"))
      if (onSaved) onSaved()
    } catch (error) {
      FlashNotifications.errorResponse(error)
    }
  }
}
